.blur-container {
    position: relative;
}

.blur-message::before {
    position: absolute;
    top: 40%;
    width: 100%;
    text-align: center;
    content: "AUCUNE DONNÉE";
    font-family: "Syne";
    font-size: 50px;
}

.blur-page {
    filter: blur(4px);
}