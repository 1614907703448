html, body, #root, .App, .App-content, .window-size {
  height: 100vh !important;
  width: 100%;
}

.nopadding {
  padding: 0 !important;
  margin: 0 !important;
}

.container-size {
  width: 100%;
  height: calc(100vh - 80px);
}

#container-arrow > div:first-child:after {
  content:'\e803';
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  font-family: "fontello";
  width: 1em;
  margin-right: .2em;
  text-align: center;
  color: var(--purple-tah-color);
  pointer-events: none;
}

.container-arrow-left > div:first-child:after {
  content:'\e803';
  position: absolute;
  left: 5px;
  top: 37%;
  transform: translateY(-50%);
  font-family: "fontello";
  width: 1em;
  margin-right: .2em;
  text-align: center;
  color: var(--purple-tah-color);
  pointer-events: none;
}

.outline-none {
  outline: none !important;
}

:root {
  /* Set the deafult values */
  --selected-primary-color: var(--light-primary-color);
  --selected-disabled-color: var(--light-disabled-color);

  /* Set the 'light' theme */
  --blue-tah-color: #91AFE5;
  --purple-tah-color: #927BDB;
  --red-tah-color: #ea1616;
  --green-tah-color: #51B292;
    --rose-tah-color: #E879B3;
    --black-tah-color: #000000;
  --white-purple-tah-color: #ece9f6;
  --yellow-tah-color: #FFEE99;
  --warning-tah-color: #ffc107;

  /* Transparent colors */
    --tr-blue-tah-color: rgba(145, 175, 229, 0.85);
    --tr-purple-tah-color: rgba(146, 123, 219, 0.85);
    --tr-green-tah-color: rgba(81, 178, 146, 0.85);
  --tr-red-tah-color: rgba(234, 22, 22, 0.85);
  --tr-black-tah-color: #0000004D;

    /* Darker colors */
    --drk-blue-tah-color: #497ad7;
    --drk-purple-tah-color: #6b4fcc;
    --drk-green-tah-color: #24c488;
    --drk-red-tah-color: #e70000;
    --drk-black-tah-color: #000000d9;
}
