.navbar-header {
  height: 80px;
  border: solid 1px var(--blue-tah-color);
  background-color: var(--purple-tah-color);
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.tbbpv {
    width: 300px;
}

.tbbp {
    width: 70vw;
}

.logout {
  background-color: transparent;
}

.logout:hover {
  background-color: var(--purple-tah-color);
  border-color: var(--purple-tah-color);
}

.logout-img {
  width: 30px;
  height: auto;
}

a.navbar-link:hover {
  text-decoration: none;
  color: #ffffff
}

span.navbar-link {
  position: relative;
  font-family: Rota;
  font-size: 1.5em;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.3px;
  color: #ffffff;
  text-transform: uppercase;
}

span.navbar-link:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: yellow;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out;
}

span.navbar-link:hover:before {
  visibility: visible;
  transform: scaleX(1);
}

span.navbar-link-active:before {
  visibility: visible;
  transform: scaleX(1);
}

.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='https://www.w3.org/2000/svg' fill='%234a25aa' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}

.carousel-control-next-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='https://www.w3.org/2000/svg' fill='%234a25aa' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}
