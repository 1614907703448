.header {
  height: 84px;
  box-shadow: 1px 1px 60px 0 rgb(8 8 8 / 20%);
  background-color: #ffffff;
}

.header-button-connexion {
  width: 145px;
  height: 40px;
  border: solid 1px var(--blue-tah-color);
  background-color: var(--blue-tah-color);
  border-radius: 0;
  font-family: Rota;
  font-size: 17px;
  color: #e6f0ff;
  line-height: 1.7;
}

.header-button-connexion:hover,
.header-button-connexion:focus,
.header-button-connexion:active {
  background-color: var(--purple-tah-color);
  border-color: var(--purple-tah-color);
  color: #e6f0ff;
}

.logo-login {
  height: 100% !important;
  width: 100% !important;
}

@media (max-width: 767px) {
  .header-button-connexion {
    width: 120px;
    height: 35px;
    font-size: 14px;
    line-height: 1.7;
  }
}
