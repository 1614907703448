/* DEFAULT BTN */
.tah-btn {
    border-radius: 0;
    font-family: Rota;
    font-size: 16px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    background-color: #ffffff;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.45);
    padding: 12px 30px 12px 30px;
}

/* PURPLE BTN */
.tah-purple-btn {
    @extend .tah-btn;
    color: var(--purple-tah-color);
    border: solid 3px var(--purple-tah-color);
}

.tah-purple-btn:active {
    outline: solid var(--tr-purple-tah-color) !important;
    background-color: var(--drk-purple-tah-color) !important;
    border-color: var(--drk-purple-tah-color) !important;
    color: #ffffff;
    box-shadow: none;
}

.tah-purple-btn:hover,
.tah-purple-btn:focus {
    @extend .tah-btn;
    background-color: var(--purple-tah-color);
    border-color: var(--purple-tah-color);
    color: #ffffff;
}

/* LIGHT BLUE BTN */
.tah-light-blue-btn {
    @extend .tah-btn;
    color: var(--blue-tah-color);
    border: solid 3px var(--blue-tah-color);
}

.tah-light-blue-btn:active:not(:disabled) {
    outline: solid var(--tr-blue-tah-color) !important;
    background-color: var(--drk-blue-tah-color) !important;
    border-color: var(--drk-blue-tah-color) !important;
    color: #ffffff;
    box-shadow: none;
}

.tah-light-blue-btn:hover:not(:disabled),
.tah-light-blue-btn:focus:not(:disabled) {
    @extend .tah-btn;
    background-color: var(--blue-tah-color);
    border-color: var(--blue-tah-color);
    color: #ffffff;
}

/* RED BTN */
.tah-red-btn {
    @extend .tah-btn;
    color: var(--red-tah-color);
    border: solid 3px var(--red-tah-color);
}

.tah-red-btn:active {
    outline: solid var(--tr-red-tah-color) !important;
    background-color: var(--drk-red-tah-color) !important;
    border-color: var(--drk-red-tah-color) !important;
    color: #ffffff;
    box-shadow: none;
}

.tah-red-btn:hover,
.tah-red-btn:focus {
    @extend .tah-btn;
    background-color: var(--red-tah-color);
    border-color: var(--red-tah-color);
    color: #ffffff;
}


/* BLACK BTN */
.tah-black-btn {
    @extend .tah-btn;
    color: var(--black-tah-color);
    border: solid 3px var(--black-tah-color);
}

.tah-black-btn:active {
    outline: solid var(--tr-black-tah-color) !important;
    background-color: var(--drk-black-tah-color) !important;
    border-color: var(--drk-black-tah-color) !important;
    color: #ffffff;
    box-shadow: none;
}

.tah-black-btn:hover,
.tah-black-btn:focus {
    @extend .tah-btn;
    background-color: var(--black-tah-color);
    border-color: var(--black-tah-color);
    color: #ffffff;
}

/* BACK BBTN */
.tah-back-btn {
    padding: 12px 20px 12px 20px !important;
}

/* GREEN BTN */
.tah-green-btn {
    @extend .tah-btn;
    color: var(--green-tah-color);
    border: solid 2px var(--green-tah-color);
    box-shadow: 0 3px 6px 0 var(--tr-green-tah-color);
}

.tah-green-btn:active {
    outline: solid var(--tr-green-tah-color) !important;
    background-color: var(--drk-green-tah-color) !important;
    border-color: var(--drk-green-tah-color) !important;
    color: #ffffff;
    box-shadow: none;
}

.tah-green-btn:hover,
.tah-green-btn:focus {
    @extend .tah-btn;
    background-color: var(--green-tah-color);
    border-color: var(--green-tah-color);
    color: #ffffff;
}