.container-arrow > div:first-child:after {
  content:'\e803';
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  font-family: "fontello";
  width: 1em;
  margin-right: .2em;
  text-align: center;
  color: var(--purple-tah-color);
  pointer-events: none;
}

.container-arrow-left > div:first-child:after {
  content:'\e803';
  position: absolute;
  left: 5px;
  top: 37%;
  transform: translateY(-50%);
  font-family: "fontello";
  width: 1em;
  margin-right: .2em;
  text-align: center;
  color: var(--purple-tah-color);
  pointer-events: none;
}

.oc-button-empty {
  border: solid 3px var(--purple-tah-color);
  border-radius: 0;
  font-family: Rota;
  font-size: 16px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: var(--purple-tah-color);
  background-color: #ffffff;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.45);
  text-transform: uppercase;
  padding: 12px 30px 12px 30px;
}

.oc-button-empty-blue {
  border: solid 3px var(--blue-tah-color);
  border-radius: 0;
  font-family: Rota;
  font-size: 16px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  color: var(--blue-tah-color);
  letter-spacing: normal;
  background-color: #ffffff;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.45);
  padding: 12px 30px 12px 30px;
}

.oc-button-empty2 { /* black button */
  border: solid 3px #282828;
  border-radius: 0;
  font-family: Rota;
  font-size: 16px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #282828;
  background-color: #ffffff;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.45);
  padding: 12px 20px 12px 20px;
  text-transform: uppercase;
}

.oc-button-empty2:hover, .oc-button-empty2:focus, .oc-button-empty2:active {
  background-color: #282828;
  border-color: #282828;
  color: #ffffff;
}

.oc-button-empty-blue:hover:not(:disabled) {
  background-color: var(--blue-tah-color);
  border-color: var(--blue-tah-color);
  color: #ffffff;
}

.oc-button-empty-blue:hover:disabled{
  cursor:not-allowed;
}

.oc-button-empty:hover, .oc-button-empty:focus, .oc-button-empty:active, .oc-button1:hover, .oc-button1:active {
  background-color: var(--purple-tah-color);
  border-color: var(--purple-tah-color);
  color: #ffffff;
}

.oc-button-clicked {
  background-color: var(--purple-tah-color);
  color: #ffffff;
  box-shadow: none;
}

.oc-button-blue-clicked{
  background-color: var(--blue-tah-color);
  color: #ffffff;
  box-shadow: none;
}

.oc-button2 { /*red cross btn*/
  border: solid 3px #d0392c;
  background-color: #ffffff;
  border-radius: 0;
  font-family: Rota;
  font-size: 18px;
  color: #d0392c;
  text-transform: uppercase;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.45);
}

.oc-button3 { /*green check btn*/
  border: solid 3px #45d54b;
  background-color: #ffffff;
  border-radius: 0;
  font-family: Rota;
  font-size: 18px;
  color: #45d54b;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.45);
  text-transform: uppercase;
}

.oc-button3:hover{
  background-color: #45d54b;
  border-color: #45d54b;
  color: #ffffff;
}

.oc-button2:hover, .oc-button2:active {
  background-color: #d0392c;
  border-color: #d0392c;
  color: #ffffff;
}

/* HIDE SCROLLBAR in MultiSelect*/
._2iA8p44d0WZ-WqRBGcAuEV::-webkit-scrollbar {
  display: none;
}

.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

.select-disable{
  pointer-events: none;
  /*background-color: rgba(74, 37, 170, 0.3) !important;*/
  opacity: .5;
  border-color: var(--purple-tah-color) !important;
}

/* Custom datepicker */
.react-datepicker{
  display: flex !important;
}
.popper-container-custom  {
  width: fit-content;
  height: 100%;
}
.react-datepicker__input-container{
  width: 100%;
}
/*END*/

.custom-btn-lightblue{
  color: var(--blue-tah-color);
  border: solid 2px var(--blue-tah-color);
  background-color: #e6f0ff;
  height: 35px;
}

.custom-btn-green:hover, .custom-btn-green:active{
  background-color: #1fb23b !important;
  color: #ffffff !important;
}

.custom-btn-green{
  background-color: white;
  color: #1fb23b !important;
  border-color: #1fb23b !important;
}

.custom-btn-red:hover, .custom-btn-red:active, .custom-btn-red:focus{
  background-color: #ea1616 !important;
  color: #ffffff !important;
}

.date-input, .date-input:active{
  height: 35px;
  border: solid 1px var(--purple-tah-color);
  background-color: #e6f0ff;
  border-radius: 0;
  outline: none !important;
}

.input-font{
  height: 35px;
  border: solid 2px var(--purple-tah-color) !important;
  background-color: #e6f0ff;
  border-radius: 0;
}

.input-font:focus, .input-font:active{
  outline: 2px solid #8665db;
  box-shadow: 0px 0px 0px 2px #b8a3f0;
  background-color: #e6f0ff;
}

.empty-box-font, .empty-box-font:active, .empty-box-font:focus{
  border: solid 3px var(--purple-tah-color);
  border-radius: 0;
  font-family: Rota;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: var(--purple-tah-color);
  background-color: #ffffff;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.45);
}

.filled-box-font{
  border: solid 3px var(--purple-tah-color);
  border-radius: 0;
  background-color: var(--purple-tah-color);
}

.police-gte-font{
  font-family: Syne;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: var(--purple-tah-color);
}

.police-gilroy-font{
  font-family: Rota;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  color: var(--purple-tah-color);
}

.oc-list-deco {
  border-bottom: 3px solid var(--purple-tah-color);
  cursor: pointer;
}

.oc-button-list {
  background-color: transparent;
  float: right;
}

.oc-title-font {
  font-family: Rota;
  font-size: 23px;
  font-weight: 800;
  text-align: left;
  color: var(--purple-tah-color);
  text-transform: uppercase;
}

.oc-title-font2 {
  font-family: Rota;
  font-size: 21px;
  font-weight: 800;
  color: var(--purple-tah-color);
}

.oc-title-font3 {
  font-family: Rota;
  font-size: 18px;
  font-weight: 800;
  color: var(--purple-tah-color);
}

.oc-button-list {
  background-color: transparent;
  float: right;
}

.display-scrollbar::-webkit-scrollbar{ /* to always display scrollbar */
  width: 7px
}

.display-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.invalid-date-input{ /* Used in datepicker because there is no style */
  border: solid 1px red
}

.underline-title-font{
  border-bottom: 5px solid;
}

.invalid-feedback-custom{
  margin-top: .25rem;
  font-size: 80%;
  color: #dc3545;
}

/* Remove arrows of input type number Chrome, Safari, Edge, Opera */
.noarrow::-webkit-outer-spin-button,
.noarrow::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.noarrow[type=number] {
  -moz-appearance: textfield;
}

.rv-xy-plot__axis__title{
  transform: translate(0,0);
}

/* Effet dégradé */
.linear-gradient{
  background: linear-gradient(rgba(255,255,255,1), rgba(255,255,255,1),rgba(255,255,255,0.95), rgba(255,255,255,0.8));
}

.text-input-font{
  font-family: Syne;
  font-size: 1rem;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #282828;
}

.no-search-input-select > .css-1jllj6i-control{
  display: none;
}

.no-search-input-select > .css-1r4vtzz{
  box-shadow: none;
}

.tah-card {
  border-width: 1px;
  border-color: var(--white-purple-tah-color);
  margin-bottom: -1px;
}

#search_input:active, #search_input:focus{ /* Remove outline of multiSelector */
  outline: none !important
}

#purple-selector > div > i{
  color: var(--purple-tah-color);
}

#react-select-3-option-30 {
  display: none;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='%238C98F2'><polygon points='0,0 100,0 50,50'/></svg>") no-repeat;
  background-size: 12px;
  background-position: calc(100% - 15px) 65%;
  background-repeat: no-repeat;
  background-color: #efefef;
}

.sos-warning-btn{
  background-color: var(--warning-tah-color) !important;
  border-radius: 0;
  border: solid 3px var(--warning-tah-color);
  color: #ffffff;
}

.sos-warning-btn:hover:not(:disabled) {
  background-color: #ffffff !important;
  color: var(--warning-tah-color) !important;
  border: solid 3px var(--warning-tah-color) !important;
}

.sos-warning-btn > svg {
  position: relative;
  left: -5px;
}
/* .sos-warning-btn:hover:disabled {

} */

input:focus::placeholder {
  color: transparent;
}

@media (max-width: 768px) {
  #title{font-size: 25px !important}
}

@media (min-width: 576px){
  .fontSize-responsive{font-size: 2vw}
}

@media (max-width: 576px){
  .custom-btn{font-size: 2.2vw !important}
  .fontSize-responsive{font-size: 2.5vw}
}

@media (min-width: 1200px){
  .custom-btn{font-size: 0.8vw}
}
